import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";

import { FaUser, FaTable, FaCreditCard, FaServer, FaChartBar, FaEnvelope } from "react-icons/fa";

import FeturesSectionWrapper from "./fetures.style";

const Underlined = ({children}) => <span className="underlined">{children}</span>

const Features = () => {
  return (
    <FeturesSectionWrapper id="features">
      <Container fullWidthSM>
        <SectionTitle UniWidth="65%">
          <h4>Use Cases</h4>
          <h2>
            <span>Common uses</span> and <span>applications</span>
          </h2>
        </SectionTitle>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              {/* <div className="fetures-icon-block">
                <img src={Icon1} alt="img" />
              </div> */}
              <h3><FaUser className="icon-left" /> Authentication</h3>
              <p>
                Store and verify user credentials with the <Underlined>Bcrypt</Underlined> block. Get user session with <Underlined>Jwt</Underlined> block.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              {/* <div className="fetures-icon-block">
                <img src={Icon6} alt="img" />
              </div> */}
              <h3><FaTable className="icon-left" /> Custom CMS</h3>
              <p>
                Use the <Underlined>MongoDB</Underlined> or <Underlined>Firestore</Underlined> block to query or update your database.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              {/* <div className="fetures-icon-block">
                <img src={Icon3} alt="img" />
              </div> */}
              <h3><FaCreditCard className="icon-left" /> Payments</h3>
              <p>
                The <Underlined>Stripe</Underlined> block allows one-off charges and recurring payments through subscriptions.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              {/* <div className="fetures-icon-block">
                <img src={Icon2} alt="img" />
              </div> */}
              <h3><FaServer className="icon-left" /> API Aggregation</h3>
              <p>
                Fetch data from any 3rd-party API like Skyscanner and Yahoo Finance using with the <Underlined>Webhook</Underlined> block.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v2">
              {/* <div className="fetures-icon-block">
                <img src={Icon4} alt="img" />
              </div> */}
              <h3><FaChartBar className="icon-left" /> Reporting</h3>
              <p>
                Query data from your database and create useful reports with the <Underlined>MongoDB</Underlined> block.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <div className="fetures-block v1">
              {/* <div className="fetures-icon-block">
                <img src={Icon5} alt="img" />
              </div> */}
              <h3><FaEnvelope className="icon-left" /> Email / SMS / Push</h3>
              <p>
                <span style={{padding: 4, backgroundColor: '#FB7B81', color: 'white', borderRadius: '6px'}}>Coming soon</span>&nbsp;Send welcome messages with the <Underlined>Email</Underlined> block or the <Underlined>Push Notifications</Underlined> block or 2FA passcodes with the SMS block.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </FeturesSectionWrapper>
  );
};

export default Features;
