import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
// import { SlickSlider, SliderItem } from "../../reusecore/SlickSlider";

import { FaQuoteLeft } from "react-icons/fa";
// import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";

import data from "../../../assets/testimonial";

import TestimonialWrapper from "./testimonial.style";

const Testimonial = () => {
  // const ArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  //   <button {...props} className="slick-arrow slick-prev-icon">
  //     <IoIosArrowRoundBack />
  //   </button>
  // );
  // const ArrowRight = ({ currentSlide, slideCount, ...props }) => (
  //   <button {...props} className="slick-arrow slick-next-icon">
  //     <IoIosArrowRoundForward />
  //   </button>
  // );
  // const settings = {
  //   dots: false,
  //   autoplay: true,
  //   infinite: true,
  //   speed: 2000,
  //   slidesShow: 1,
  //   slidesToScroll: 1,
  //   prevArrow: <ArrowLeft />,
  //   nextArrow: <ArrowRight />
  // };

  return (
    <TestimonialWrapper id="testimonial">
      <Container>
        <Row>
          <Col xs={12}>
            <SectionTitle UniWidth="65%">
              <h4>User Stories</h4>
              <h2>
                Things our <span>users say</span>
              </h2>
            </SectionTitle>
            </Col>
          </Row>
          <Row>
            {data.testimonials.slice(0,3).map((testimonial, index) => (
              <Col xs={12} sm={4} key={index} style={{padding: 20}}>
                <p>
                  <FaQuoteLeft style={{marginRight: 12}}/>
                  {testimonial.quote}
                  <FaQuoteLeft style={{marginLeft: 12, transform: 'rotate( -180deg )'}}/>
                </p>
                {/* <div className="slider__meta">
                  <img src={testimonial.thumb} alt="img" />
                  <div className="testimonial-client">
                    <h6>{testimonial.author}</h6>
                    <p>{testimonial.dsignation}</p>
                  </div>
                </div> */}
              </Col>
            ))}
        </Row>
      </Container>
    </TestimonialWrapper>
  );
};

export default Testimonial;
