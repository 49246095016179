import React from "react";
// import Link from "next/link";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
// import Button from "../Base/Button";
// import VintageBox from "../Base/VintageBox";

import integrationImage1 from "../../../assets/integrations/express.png";
import integrationImage2 from "../../../assets/integrations/jwt.png";
import integrationImage3 from "../../../assets/integrations/stripe.png";
import integrationImage4 from "../../../assets/integrations/mongodb.png";
// import integrationImage4 from "../../../assets/integrations/firestore.png";
// import Icon from "../../../assets/integrations/arrow.png";

import IntegrationItemWrapper from "./integrationSection.style";

const Integrations = () => {
  return (
    <IntegrationItemWrapper>
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            <div className="integration__block__wrap">
              <div className="integration__block">
                <div className="integration__block__inner">
                  <img src={integrationImage1} alt="appion integration" />
                  <h3>Express</h3>
                  <p>HTTP</p>
                  {/* <Link href="#">
                    <img src={Icon} alt="appion app" />
                  </Link> */}
                </div>
                <div className="integration__block__inner">
                  <img src={integrationImage2} alt="appion integration" />
                  <h3>Jwt</h3>
                  <p>Authentication</p>
                  {/* <Link href="#">
                    <img src={Icon} alt="appion app" />
                  </Link> */}
                </div>
              </div>
              <div className="integration__block right">
                <div className="integration__block__inner">
                  <img src={integrationImage3} alt="appion integration" />
                  <h3>Stripe</h3>
                  <p>Payments</p>
                  {/* <Link href="#">
                    <img src={Icon} alt="appion app" />
                  </Link> */}
                </div>
                <div className="integration__block__inner">
                  <img src={integrationImage4} alt="appion integration" />
                  <h3>MongoDB</h3>
                  <p>Database</p>
                  {/* <Link href="#">
                    <img src={Icon} alt="appion app" />
                  </Link> */}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} className="integration-content-wrap">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>Integrations</h4>

              <h2>
                Authentication, databases, payments <span>and more</span>.
              </h2>
            </SectionTitle>
            <p className="integration-text">
              Every endpoint requires a <span>Request</span> block for receiving the request and getting the HTTP headers, query params and body and one or more <span>Reponse</span> block for sending out data. There are the <span>Bcrypt</span> and <span>Jwt</span> blocks for json web token authentication, <span>Stripe</span> block for handling payments, a <span>Code</span> block that supports Javascript for more complex needs and database integrations such as <span>MongoDB</span>, <span>Firestore</span>, <span>EasyDB</span> integrations. <span style={{fontWeight: 'normal', textDecoration: 'underline', fontStyle: 'italic'}}>SQL support (Postgres &amp; MySQL) is coming soon.</span>
            </p>
            {/* <VintageBox
              right={true}
              vintageTwo={true}
              position="relative"
            >
              <Button className="integration-btn">Learn More</Button>
            </VintageBox> */}
          </Col>
        </Row>
      </Container>
    </IntegrationItemWrapper>
  );
};

export default Integrations;
