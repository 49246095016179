import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
import Button from "../Base/Button";
import VintageBox from "../Base/VintageBox";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
} from "../Base/Accordion";

import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import FaqImage from "../../../assets/about/about.png";
import particle1 from "../../../assets/particles/07.png";
import particle2 from "../../../assets/particles/08.png";

import data from "../../../assets/faq";

import FaqSectionWrapper from "./faqSection.style";

const Faq = () => {
  return (
    <FaqSectionWrapper id="faq">
      {/* <img className="section__particle one" src={particle1} alt="img" /> */}
      {/* <img className="section__particle two" src={particle2} alt="img" /> */}
      <Container >
        <Row Vcenter={true} style={{maxWidth: '960px', margin: '0 auto'}}>
          <Col className="faq-content-block">
            <SectionTitle
              className="section-title"
              UniWidth="65%"
            >
              <h4>FAQ</h4>
              <h2>
                Commonly asked <span>questions</span>
              </h2>
            </SectionTitle>
            <Accordion>
              {data.faqs.map((faq, index) => (
                <AccordionItem key={index}>
                  <AccordionTitle>
                    <h5>{faq.title}</h5>
                    <IconWrapper>
                      <OpenIcon>
                        <IoIosArrowForward />
                      </OpenIcon>
                      <CloseIcon>
                        <IoIosArrowDown />
                      </CloseIcon>
                    </IconWrapper>
                  </AccordionTitle>
                  <AccordionBody>
                    <p>{faq.content}</p>
                  </AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </FaqSectionWrapper>
  );
};

export default Faq;
