import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";

import AppScreenSectionWrapper from "./appScreenSection.style";

import imgAbout from "../../../assets/about/devices.png";

const AppScreens = () => {
  return (
    <AppScreenSectionWrapper>
      <div className="app-screen-two-block">
        <Container>
          <Row>
          <Col xs={12} sm={6}>
            <img
              className="about-thumb"
              src={imgAbout}
              alt="This is about UNI-Prime"
            />
          </Col>
            <Col className="app-screen-content-block" xs={12} sm={6} style={{display: 'flex', alignItems: 'center'}}>
              <SectionTitle
                  className="section-title"
                  leftAlign={true}
                  UniWidth="100%"
                >
                  <h4>Data Ownership</h4>
                  <h2 style={{marginBottom: '40px'}}>
                    <span>100% control</span> of your data
                  </h2>
                  <p style={{color: 'white'}}>
                  Data stored can be stored in <span>MongoDB</span>, <span>Firestore</span> or <span>EasyDB</span>. That means that you can view and edit your data from MongoDB compass or OpenBase or Firestore cloud dashboard. At the same time you can build any other app to connect to the database as you would if you were building with code. 
                  <br/>
                  <br/>
                  <b>Coming Soon:</b> SQL support (Postgres &amp; MySQL)
                  </p>
                </SectionTitle>
            </Col>
          </Row>
        </Container>
    </div>
    </AppScreenSectionWrapper>
  );
};

export default AppScreens;
