import SEO from "../components/Head/Social"

import Navigation from '../components/Landing/Navigation';
import Banner from '../components/Landing/Banner'; 
import About from '../components/Landing/About';
import About2 from '../components/Landing/About2';
import Features from '../components/Landing/Features';
import Faq from '../components/Landing/Faq';
import Integrations from '../components/Landing/Integrations';
import AppScreens from '../components/Landing/AppScreens';
import Video from '../components/Landing/Video';
import Testimonial from '../components/Landing/Testimonial';
import Footer from '../components/Landing/Footer';
import GetStarted from "../components/Landing/GetStarted";

// import Howto from '../components/HowTo';
// import Examples from '../components/Examples';

// import Contact from '../components/Contact';

const title = "Try Diagram - The most complete Nocode API builder"
const description = "Native nocode backend in minutes with simple drag-and-drop nodes. Supports MongoDB, Firestore, EasyDB, JWT tokens, Stripe and others."

const IndexPage = () => (
  <>
    <SEO title={title} description={description}/>
    <Navigation />
    <Banner />
    <Video/>
    <About />
    <About2 />
    <AppScreens />
    
    {/* <Howto /> */}
    {/* <Examples/> */}

    <Integrations />
    <Features />
    <GetStarted />
    <Testimonial />
    <Faq />
    <Footer />
  </>
)

export default IndexPage
