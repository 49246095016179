import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
import Button from "../Base/Button";

import GetStartedWrapper from "./getStarted.style";

import imgAbout from "../../../assets/about/server.png";

import { useRouter } from "next/router";

const GetStarted = () => {
  
  const router = useRouter()

  return (
    <GetStartedWrapper id="get-started">
      <Container>
        <div style={{padding: '40px 20px', backgroundColor: '#2A2039', borderRadius: '16px'}}>
        <Row style={{margin: '0 auto'}}>
        <Col xs={12} sm={6} style={{padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <img
              className="about-thumb"
              src={imgAbout}
              alt="This is about UNI-Prime"
              style={{height: '400px'}}
            />
          </Col>
          <Col xs={12} sm={6} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <SectionTitle className="section-title" UniWidth="100%">
              <h4>Build &amp; Scale</h4>
              <h2><span>Nocode backend</span> in minutes</h2>
            </SectionTitle>
              <div style={{marginBottom: '32px', lineHeight: 2}}>
              ✓ Build your endpoints with simple <span>drag</span> and <span>drop</span> blocks<br/>
              ✓ Supports <span>MongoDB</span>, <span>Firestore</span> &amp; <span>EasyDB</span><br/>
              ✓ Allows for different enviroments such as dev, stag and prod <br/>
              </div>
              <div style={{textAlign: 'center'}}>
              <Button className="get-started-btn" onClick={() => router.push('/app')}>Get Started</Button>
              </div>
          </Col>
          </Row>
        </div>
      </Container>
    </GetStartedWrapper>
  );
};

export default GetStarted;
