import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
import Button from "../Base/Button";
import VintageBox from "../Base/VintageBox";

import imgAbout from "../../../assets/about/grow2.png";
import particle1 from "../../../assets/particles/05.png";
import particle2 from "../../../assets/particles/06.png";

import AboutSectionWrapper from "./about.style";

const About = () => {
  return (
    <AboutSectionWrapper id="about">
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col xs={12} sm={6} className="about-content-wrap left-col">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              {" "}
              <h4>Scale</h4>
              <h2>
                <span>No deployment</span> is required. You are live!
              </h2>
            </SectionTitle>
            <p className="about-text text-one">
              There is no need for managing servers, version deployments, 3rd party libraries packages etc. The endpoints of the nocode backend are automatically live and can be called from your frontend right away. There is a <span>Debug panel</span> to test the endpoints for different <u>Headers</u> and <u>Body</u> parameters.
            </p>
            {/* <p className="about-text text-two">
              <b>Environments</b>
              <div>Setup 🛠 <span>Development</span>, 🎭 <span>Staging</span> and 🗃 <span>Production</span> with different databases to better manage QA and releasing.</div>
            </p> */}
          </Col>
          <Col xs={12} sm={6} className="right-col">
            <img
              className="about-thumb"
              src={imgAbout}
              alt="This is about UNI-Prime"
            />
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  );
};

export default About;
