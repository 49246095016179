import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
import Button from "../Base/Button";
import VintageBox from "../Base/VintageBox";
import ParticleComponent from "../Base/Particle";

import { FaRocket } from "react-icons/fa";
// import { FaPlay, FaVideo } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";

import { useRouter } from "next/router";

const BannerDefault = () => {

  const router = useRouter()

  return (
    <BannerSectionWrapper>
      <ParticleComponent />
      <Container >
        <Row style={{maxWidth: '780px', margin: '0 auto'}}>
          <Col>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h4>Up to 1k API free calls per month</h4>
              <h1>
                Backend in minutes <br/>with <span>simple blocks</span>
              </h1>
            </SectionTitle>
            <p>
              Flexible lightening-fast nocode backend with simple drag-and-drop blocks. Supports authentication, SQL and NoSQL databases, Stripe payments and 3rd party APIs.
            </p>
            <VintageBox right={true} vintageOne={true}>
              <Button className="banner-btn one"  onClick={() => router.push('/app')}>
                <FaRocket className="icon-left" /> Get started
              </Button>
              {/* <a href="/app" onClick={() => {captureOutboundLink('/app'); return false;}}>
                <Button className="banner-btn two" style={{marginLeft: 20}}>
                  <FaPlay className="icon-left" /> Video
                </Button>
              </a> */}
            </VintageBox>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerDefault;
