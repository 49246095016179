import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";

import VideoSectionWrapper from "./video.style";

import TutorialVideo from "../../../assets/videos/walkthrough_720p.mp4"
import TutorialThumbnail from "../../../assets/videos/thumbnail.jpeg"

const Video = () => {
  return (
    <VideoSectionWrapper id="video">
      <Container fullWidthSM>
        <SectionTitle UniWidth="65%" style={{width: '520px'}}>
          <h4>Video</h4>
          <h2>
            Building <span>an endpoint</span> in 120 seconds
          </h2>
        </SectionTitle>
        <Row>
          <Col xs={12} style={{textAlign: 'center'}} autostart="false">
            <video preload="metadata" controls style={{width: '920px', maxWidth: '100%', padding: 20}} poster={TutorialThumbnail}>
              <source src={TutorialVideo} type="video/mp4" />
            </video>
          </Col>
        </Row>
      </Container>
    </VideoSectionWrapper>
  );
};

export default Video;
