import React from "react";

import { Container, Row, Col } from "../Base/Layout";
import SectionTitle from "../Base/SectionTitle";
import Button from "../Base/Button";
import VintageBox from "../Base/VintageBox";

import imgAbout from "../../../assets/about/build.png";
import particle1 from "../../../assets/particles/05.png";
import particle2 from "../../../assets/particles/06.png";

import AboutSectionWrapper from "./about.style";

const About = () => {
  return (
    <AboutSectionWrapper id="about">
      <img className="section__particle one" src={particle1} alt="img" />
      <img className="section__particle two" src={particle2} alt="img" />
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col xs={12} sm={6}>
            <img
              className="about-thumb"
              src={imgAbout}
              alt="This is about UNI-Prime"
            />
          </Col>
          <Col xs={12} sm={6} className="about-content-wrap">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              {" "}
              <h4>Build</h4>
              <h2>
                <span>Drag and drop</span> your new backend API.
              </h2>
            </SectionTitle>
            <p className="about-text text-one">
              Use a few basic building blocks to build complex API endpoints. Connect to your <span>MongoDB</span> or <span>Firestore</span> database. Setup authorization with JSON web tokens, payments with Stripe, email with <span>Nodemailer</span> or SMS with <span>Twilio</span>.
            </p>
            {/* <p className="about-text text-two">
              <b>Example</b>
              <div>Check the auth header with the 🔒 JWT block and get the user's data with the 🗒 MongoDB block.</div>
            </p> */}
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  );
};

export default About;
