import styled from 'styled-components';

// import AppSecreenBG from '../../assets/images/app/get-app/get-app-bg.png';

// import AppSecreenBGTwo from '../../assets/images/app/app-screen/05.png';

// background: url(${AppSecreenBG}) no-repeat;
// background: url(${AppSecreenBGTwo}) no-repeat;
const AppScreenSectionWrapper = styled.div`
    .app-screen-two-block{
        margin: 60px 0px;
        padding: 60px 0;
        background-color: #2A2039;
        background-attachment: fixed;
        background-size: cover;
        background-position: 70% center;
        span{
            font-weight: bold;
        }
        .section-title{
        h4,h2{
            color: ${props => props.theme.white};
        }
        }
        .btn-group{
        display: flex;
        }
        .btn-outline{
        padding: 13px 17px;
        border: 1px solid ${props => props.theme.white};
        border-radius: 5px;
        color: ${props => props.theme.white};
        background: transparent;
        transition: all 0.3s ease-in;
        &:first-child{
            margin-right: 15px;
        }
        &:hover{
            background: ${props => props.theme.white};
            color: ${props => props.theme.primaryColor};
        }
        }
    }
    
`


export default AppScreenSectionWrapper;