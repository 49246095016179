// Diagram was exatcly to deal with these needs.
const data = {
    faqs: [
      {
        title: 'How is Diagram different from Google Firebase and AWS Amplify?',
        content: 'Both Firebase and Amplify are platforms that have their own managed database where they store data. Integrations with the database are hard and migrating out of these platforms is almost impossible. Diagram was built exactly to deal with this problem and provide be nocode backend platform that plays nicely popular database system such as MongoDB and Postgres.'
      },
      {
        title: 'Is data ownership the same as data privacy? What is it exactly?',
        content: 'It means that we don\'t host your data. Instead you can connect your databases to Diagram directly. It also means that you can backup them, query them with other backend tools, use reporting software and safely delete them if you need to without dealing with Diagram.'
      },
      {
        title: 'What is the latency of the API calls? Can it scale to handle 1k reqs/sec?',
        content: 'Diagram evaluation algorithm has minimal footprint and does not affect the endpoint response time in a noticible way. Diagram lies on Google Cloud Run which is a serverless technology and scales accordingly to the demand. Sometimes our container goes cold and the waking request will need 3-5 seconds to start.'
      },
      {
        title: 'How does it comparing with automation tools like Zapier, IFTTT and Integromat?',
        content: 'Diagram core engine makes it more than an automation tool. It is focused on the endpoint evaluation speed to replace an API built in NodeJS or Python. Yet, this doesn\'t mean that you shouldn\'t use Diagram for integrations when you see fit.'
      }
    ]
    
  };
  export default data;
  