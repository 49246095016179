import image1 from './testimonial-thumb-1.jpg';
import image2 from './testimonial-thumb-2.jpg';
import image3 from './testimonial-thumb-3.jpg';
import image4 from './testimonial-thumb-4.jpg';
import image5 from './testimonial-thumb-5.jpg';
import image6 from './testimonial-thumb-6.jpg';

const data = {
  thumbs: [image1, image2, image3, image4, image5, image6],
  testimonials: [
    {
      quote:
        'It\'s just simple drag and drop and you build a backend which used to be the hardest bit about building software.',
      author: '-',
      dsignation: '-',
      thumb: image1
    },
    {
        quote:
          'The dev team really likes how Diagram plays nice with our existing backend. We don’t use Diagram for everything but for things we do it\'s at least 90% easier.',
        author: '-',
        dsignation: '-',
        thumb: image3
    },
    {
        quote:
          'It\'s sweet you can test product ideas that fast. My new favourite nocode stack is Webflow + Diagram + MongoDB. I call it WDM!',
        author: '-',
        dsignation: '-',
        thumb: image3
    },
    {
      quote:
        'We use Diagram for our weekly reporting. We have a Cron Job on Cloud Scheduler that triggers our custom metrics queries and works like a charm.',
      author: '-',
      dsignation: '-',
      thumb: image2
    }
  ]
};
export default data;
