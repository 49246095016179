import styled from 'styled-components';

const FeturesSectionWrapper = styled.section`
   padding: 60px 0 40px 0;
  
   span.underlined{
        text-underline-offset: 2px;
        text-decoration: underline;
        text-decoration-color: #FB7B81;
        text-decoration-thickness: 4px;
   }
    @media only screen and (max-width: 912px) {
        padding: 80px 0 0px 0;
    }
    @media only screen and (max-width: 760px) {
        padding: 80px 0 50px 0;
    }
    
`

export default FeturesSectionWrapper;