import styled from 'styled-components';

const GetStartedWrapper = styled.section`
    margin: 60px 0px;
    padding: 20px;
    overflow: hidden;
    align-items: center;
    color: white;
    span{
        font-weight: bold;
    }
    button{
        transition: 450ms all;
        background: #FFEBEC;
        color: #FB7B81;
        font-size: 16px;
        height: 58px;
        &:hover{
            background: #FB7B81;
            color: #fff;
        }
    }
    .section-title{
        h2{
            margin-bottom: 20px;
            color: white;
            font-size: 30px;
        }
        h4{
            color: white;
        }
    }

    .get-started-btn{
        background: #EEE6FA;
        color: #2A2039;
        &:hover{
            background: #EEE6FA98;
            color: #2A2039;
        }
    }
    
`

export default GetStartedWrapper;